import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const Image = styled(({ imageData, className }) => {
  return (
    <div className={`${className} h-100 w-100`}>
      <BackgroundImage
        className="d-flex align-items-center background-image"
        fluid={imageData.image.imageFile.childImageSharp.fluid}
        alt={
          imageData.image.altText ? imageData.image.altText : imageData.title
        }
      />
    </div>
  )
})`
  position: relative;

  @media (min-width: 992px) {
    position: absolute;
  }

  .background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 340px;

    @media (min-width: 992px) {
      height: 440px;
    }
  }
`

Image.propTypes = {
  imageData: object.isRequired
}

Image.defaultProps = {
  imageData: {}
}

export default Image
