import React from 'react'
import { arrayOf, object } from 'prop-types'
import styled from '@emotion/styled'

import Hr from '@components/Hr'

import colors from '@styles/js/colors'

const Locations = styled(({ locations, className }) => (
  <div className={`${className} d-flex`}>
    {locations.map((item, index) => (
      <span key={index} className="location mb-3 mb-md-0 mb-lg-5">
        <p className="text-white font-20 mb-2 text-center text-lg-left">
          {item.location}
        </p>
        <p className="text-white text-center text-lg-left">{item.address}</p>
        <Hr color={colors.saffron} small="50px" />
      </span>
    ))}
  </div>
))`
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 992px) {
    flex-direction: column;
  }

  .location {
    flex: 1;
    &:last-of-type {
      margin-bottom: 0 !important;
    }

    hr {
      margin: 0 auto;

      @media (min-width: 992px) {
        margin: 0;
      }
    }
  }
`

Locations.propTypes = {
  locations: arrayOf(object).isRequired
}

Locations.defaultProps = {
  locations: {}
}

export default Locations
