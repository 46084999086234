import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// colors
import colors from '@styles/js/colors'

// assets
import DoubleQuotes from '@images/double-quotes.svg'

const Quote = styled(({ data: { text, author, role, size }, className }) => (
  <Container className={`${className} quote`}>
    <Row className="justify-content-center">
      <Col xs={size === 'short' ? 8 : 10}>
        <DoubleQuotes />
        <p className="text">{text}</p>
        {author && role && (
          <p className="autor">
            <span>{author}</span>, {role}
          </p>
        )}
      </Col>
    </Row>
  </Container>
))`
  position: relative;

  .double-quotes {
    left: -16px;
    position: absolute;
  }

  .text {
    font-size: 18px !important;
    letter-spacing: 0.03em;
    line-height: 22px;
    padding-top: 16px;
    position: relative;
    z-index: 100;

    @media (min-width: 768px) {
      font-size: 24px !important;
      line-height: 36px;
    }
  }

  .autor {
    color: ${colors.black};
    font-size: 16px;
    margin-bottom: 0;

    span {
      font-weight: bold;
    }
  }
`

Quote.propTypes = {
  data: object.isRequired
}

Quote.defaultProps = {
  data: {}
}

export default Quote
