import styled from '@emotion/styled'

import colors from '@styles/js/colors'

const ShapeGreenRight = styled.div`
  @media (min-width: 768px) {
    background: linear-gradient(
      to bottom right,
      transparent 0%,
      transparent 50%,
      ${colors.greenLight} 50%,
      ${colors.greenLight} 99%
    );
    background-position-x: -164%;
    background-size: 162%;
    overflow: hidden;
  }
`

export default ShapeGreenRight
