import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

import colors from '@styles/js/colors'

const Square = styled(
  ({ data: { backgroundcolor, title, text }, className }) => (
    <div className={`${className} mx-lg-auto`}>
      <div
        className={`${backgroundcolor} wrapper d-flex flex-column justify-content-center h-100 p-5`}
      >
        <p className="mb-4">{title}</p>
        <p className="mb-0">{text}</p>
      </div>
    </div>
  )
)`
  .wrapper {
    @media (min-width: 992px) {
      min-height: 330px;
    }

    p {
      color: ${colors.white};
      font-family: 'Roboto-Light', sans-serif;
      font-size: 18px;
      line-height: 22px;

      @media (min-width: 992px) {
        font-size: 20px !important;
        line-height: 24px !important;
      }
    }
  }
`

Square.propTypes = {
  data: object.isRequired
}

Square.defaultProps = {
  data: {}
}

export default Square
