import React from 'react'
import Img from 'gatsby-image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '@components/SEO'
import Quote from '@components/Quote'
import Hr from '@components/Hr'
import CarouselBodyButton from '@components/CarouselBodyButton'
import HeroSmall from './components/HeroSmall'
import Square from './components/Square'
import Value from './components/Value'
import Locations from './components/Locations'
import ShapeGreenRight from './components/ShapeGreenRight'
import ProjectForm from '@components/ProjectForm'

// graphql
import { companyContent } from './modules/graphql'

const Company = () => {
  if (!companyContent()) {
    if (typeof window !== 'undefined' && window) {
      window.location.href = '/'
    }
    return <></>
  }

  const seoData = companyContent()[0]
  const heroData = companyContent()[1]
  const quoteData = companyContent()[2]
  const missonVisonData = companyContent()[3]
  const valuesData = companyContent()[4]
  const mapData = companyContent()[5]
  const cardsStandardData = companyContent()[6]
  const projectFormData = companyContent()[7]

  return (
    <>
      <SEO title={seoData.title} description={seoData.description} />
      {/* Hero */}
      <section className="mb-0 mb-lg-5">
        <div className="mb-md-3 d-inline-block w-100">
          <div className="mb-5">
            <HeroSmall data={heroData} />
          </div>
        </div>
      </section>
      {/* Quote */}
      <section className="my-md-3 d-inline-block w-100">
        <Container className="px-md-0 mb-5">
          <Row className="justify-content-center">
            <Col xs={10} md={11} lg={10}>
              <Quote data={quoteData} />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Mission and Vission */}
      <section className="mb-md-3 d-inline-block w-100">
        <Container className="mb-5">
          <Row className="justify-content-md-center">
            <Col lg={10}>
              <Row>
                {missonVisonData.item.map((item, index) => (
                  <Col xs={12} md={6} key={index}>
                    <Square data={item} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Values */}
      <section>
        <ShapeGreenRight>
          <div className="mb-md-3 d-inline-block w-100">
            <Container className="mb-5">
              <Row>
                <Col>
                  <h2 className="mb-3 seo-h2">
                    {valuesData.headingone}
                    <br className="d-none d-md-block"></br>
                    {valuesData.headingtwo}
                  </h2>
                  <Hr className="mb-0" />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="mb-md-3 d-inline-block w-100">
            {valuesData.valuecontent.map((item, valueIndex) => {
              return (
                <div key={valueIndex}>
                  <div className="mb-5" key={valueIndex}>
                    <Value
                      data={item}
                      fluid={item.image.imageFile.childImageSharp.fluid}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </ShapeGreenRight>
      </section>
      {/* Map */}
      <section className="bg-cerulean-blue">
        <div className="mb-md-3 d-inline-block w-100 p-5">
          <div className="max-width-body">
            <Container fluid>
              <Row className="align-items-center">
                <Col lg={9} className="d-none d-sm-block">
                  <Img
                    fluid={
                      mapData.backgroundimage.imageFile.childImageSharp.fluid
                    }
                    alt={
                      mapData.backgroundimage.altText
                        ? mapData.backgroundimage.altText
                        : 'World Map'
                    }
                  />
                </Col>
                <Col lg={3}>
                  <div className="mt-0 mt-sm-5 mt-lg-0">
                    <Locations locations={mapData.locations} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
      {/* Meet the team, view our services & Our work */}
      <section className="mb-3 d-inline-block w-100">
        <div className="mb-5 mt-5 w-100">
          <Container>
            <Row>
              <Col>
                <CarouselBodyButton data={cardsStandardData} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <ProjectForm data={projectFormData} />
    </>
  )
}

export default Company
