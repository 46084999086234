import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BackgroundImage from 'gatsby-background-image'

import colors from '@styles/js/colors'

const Value = styled(
  ({ data: { title, text, imageposition, image }, fluid, className }) => {
    const dataHelpers = imageposition === 'left' ? 'col-12 col-md-5 col-lg-4 order-md-2' : 'col-12 col-md-5 col-lg-4';
    const imgHelpers = 'col-12 col-md-7 col-lg-8'
    return (
      <div className={className}>
        <Container>
          <Row className="no-gutters">
            <Col className={dataHelpers}>
              <div className="data content d-flex align-content-center flex-wrap">
                <p className="title">{title}</p>
                <p className="text">{text}</p>
              </div>
            </Col>
            <Col className={imgHelpers}>
              <BackgroundImage
                className="bg-value w-100 position-relative"
                fluid={fluid}
                alt={`${image.altText ? image.altText : title} Value`}
                backgroundColor={colors.saffron}
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
)`
  .data {
    background-color: ${colors.white};

    @media (min-width: 768px) {
      min-height: 350px;
      padding: 0 44px;
    }

    .title {
      font-family: 'Roboto-Light', sans-serif;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        font-size: 36px;
        line-height: 46px;
      }
    }

    .text {
      color: ${colors.gray};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
    }
  }

  .bg-value {
    background-position: center;
    background-size: cover;
    height: 240px;

    @media (min-width: 768px) {
      height: 350px;
    }
  }
`

Value.propTypes = {
  data: object.isRequired,
  fluid: object.isRequired
}

Value.defaultProps = {
  data: {},
  fluid: {}
}

export default Value
