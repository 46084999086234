import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Hr from '@components/Hr'

import colors from '@styles/js/colors'

const Body = styled(({ data: { title, ptext }, className }) => (
  <div className={`${className} h-100 w-100`}>
    <Container className="h-100">
      <Row className="align-items-end h-100">
        <Col lg={7}>
          <div className="px-3 px-md-5 py-5 bg-cerulean-blue box">
            <h1 className="text-white mb-3">{title}</h1>
            <Hr color={colors.fern} className="mb-3" />
            {ptext.map((item, index) => (
              <p className="text-white" key={index}>
                {item.paragraph}
              </p>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  margin-top: -100px;
  position: relative;

  @media (min-width: 768px) {
    margin-top: -170px;
  }

  @media (min-width: 992px) {
    margin-top: 0;
    position: absolute;
  }

  .box {
    @media (min-width: 992px) {
      margin-bottom: -4rem;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 38px;

    @media (min-width: 992px){
      font-size: 48px;
      line-height: 58px;
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

Body.propTypes = {
  data: object.isRequired
}

Body.defaultProps = {
  data: {}
}

export default Body
