import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Company from '@compositions/Company'

const CompanyPage = () => {
  return (
    <Layout>
      <Company />
    </Layout>
  )
}

export default CompanyPage
